import React, { useEffect } from "react"

import { Link } from "gatsby"
import { useIntl } from "react-intl"

import { Body, Button, Container, Heading, Loader } from "~/components/ui"
import { useActivateEmailMutation } from "~/state/api/auth"
import withTemplate, {
  TemplateDefaultPropTypes,
} from "~/templates/withTemplate"

import css from "./Validation.module.scss"

function Validation(props: TemplateDefaultPropTypes) {
  const intl = useIntl()

  const authId = props.location.pathname.split("/")[4]
  const activationKey = props.location.pathname.split("/")[5]

  const validateEmailBody = {
    authId,
    activationKey,
  }

  const [
    validateEmail,
    {
      isLoading: validateEmailIsLoading,
      isError: validateEmailIsError,
      isSuccess: validateEmailIsSuccess,
    },
  ] = useActivateEmailMutation()

  useEffect(() => {
    validateEmail(validateEmailBody)
  }, [])

  return (
    <Container className={css.root}>
      {validateEmailIsLoading && <Loader />}
      {(validateEmailIsSuccess || validateEmailIsError) && (
        <>
          <Heading>
            {validateEmailIsSuccess
              ? intl.formatMessage({
                  id: "email-validation/text:success-validation",
                })
              : intl.formatMessage({
                  id: "email-validation/text:error-validation",
                })}
          </Heading>
          <Button color="original" variant="primary">
            <Link to="/">
              <Body variant="body2" color="white" semiBold>
                {intl.formatMessage({ id: "action/back-home-page" })}
              </Body>
            </Link>
          </Button>
        </>
      )}
    </Container>
  )
}

export default withTemplate(Validation)
